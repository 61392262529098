<template>
  <main class="color-primary h-100 cancellation-bg blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('CANCELLATION.CANCELLATION') }}
    </p>
    <v-container v-if="Object.keys(suppliers).length !== 0">
      <v-row>
        <v-col
          v-for="(supplier_item, index) in suppliers.prepaid" 
          :key="index"
          md="2"
          sm="4" 
          cols="6"
        >
          <router-link :to="{name: 'CancelPrepaid', params: {id: supplier_item.id }}" tag="a" class="link h-100" exact>
            <div class="brand-item h-100" 
              :style="{'background-color': supplier_item.logo_background_color}"
            >
              <div class="brand-item-image d-flex justify-content-center align-items-center h-100">
                <img :src="supplier_item.image" alt="">
              </div>
              <p class="brand-item-title ma-0"
                :style="{'color': supplier_item.font_color}"
              >
                {{ supplier_item.name }}
              </p>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: "CancelPayment",
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      suppliers: (state) => state.supplier.supplier_items,
      loading: (state) => state.supplier.loading
    }),
  },
  created() {
    this.getAllSuppliers();
  },
  methods: {
    ...mapActions('supplier', {
      getAllSuppliers: 'getAll',
    }),
  }

}
</script>

<style lang="scss" scoped>
.brand-item {
  position: relative;
  background-color: #2DD5C4;
  padding-bottom: 21px;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .brand-item-image {
    padding: 15px 0;
    img {
      max-height: 80px;
      max-width: 80%;
    }
  }
  .brand-item-title {
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    font-size: 14px;
    text-align: center;
    width: 100%;
    color: #fff;
  }
}
</style>